<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <template v-if="!isEmpty(details)">
        <a-descriptions :title="details.cnName">
          <a-descriptions-item label="CAS号">
            {{ details.cas }}
          </a-descriptions-item>
          <a-descriptions-item label="UN号">
            {{ details.unCode }}
          </a-descriptions-item>
          <a-descriptions-item v-if="details.enName" label="英文名称">
            {{ details.enName }}
          </a-descriptions-item>
          <a-descriptions-item v-if="details.cnAliasName" label="中文别名">
            {{ details.cnAliasName }}
          </a-descriptions-item>
          <a-descriptions-item v-if="record.cnCategory" label="危险性类别">
            {{ record.cnCategory }}
          </a-descriptions-item>
          <a-descriptions-item v-if="record.cover" label="">
            <img :src="record.cover" style="width: 70px;height: 70px"/>
          </a-descriptions-item>
        </a-descriptions>
        <div style="margin-bottom: 15px;text-align: right"
             v-action:show
        >
          <a-button type="primary" @click="showInstructions">查看用户上传说明书信息</a-button>
        </div>
        <a-collapse accordion>
          <a-collapse-panel v-for="(item,index) in list" :key="index" :header="item.name">
            <table border="1" style="border-color: #e8e8e8;width: 100%">
              <tr v-for="i3 in detailsList[item.field]">
                <td style="width: 20%;padding: 5px;font-weight: bold;text-align: center">{{ i3.name }}</td>
                <td style="width: 80%;padding: 5px">{{ details[i3.field] || '无资料' }}</td>
              </tr>
            </table>
          </a-collapse-panel>
        </a-collapse>
        <div style="margin-top: 25px">
          免责声明：<br>
          查询结果来源于网络，罐子科技不负责其合法性、准确性判断，并不对使用该类信息的结果承担任何法律责任。同时，提醒您审慎判断！
        </div>
      </template>
      <template v-else>
        <div style="height: 400px;display: flex;align-items: center;justify-content: center">
          <a-empty description="抱歉！，该化合物暂无详情~"/>
        </div>
      </template>
    </a-spin>
    <instructions-list-model ref="InstructionsListModel"/>
  </a-modal>
</template>
<script>
import * as Api from '@/api/msds/info'
import {STable} from "@/components";
import {isEmpty} from "@/utils/util";
import InstructionsListModel from './InstructionsListModel'

export default {
  name: "FixListModel",
  components: {
    STable,
    InstructionsListModel
  },
  data() {
    return {
      // 标题
      title: 'MSDS查看详情',
      // 查询参数
      queryParam: {},
      // modal(对话框)是否可见
      visible: false,
      loading: false,
      isLoading: false,

      list: [
        {name: '化学品标识', field: 'huaxuepinbiaoshi'},
        {name: '危险性概述', field: 'weixianxinggaishu'},
        {name: '成分/组成信息', field: 'chengfenzucheng'},
        {name: '急救措施', field: 'jijiucuoshi'},
        {name: '消防措施', field: 'xiaofangcuoshi'},
        {name: '泄漏应急处理', field: 'xielouyingjichuli'},
        {name: '操作处置与储存', field: 'caozuochuzhiyuchucun'},
        {name: '接触控制/个体防护', field: 'jiechukongzhigetifanghu'},
        {name: '理化特性', field: 'lihuatexing'},
        {name: '运输信息', field: 'yunshuxinxi'},
      ],
      detailsList: {
        huaxuepinbiaoshi: [
          {name: '化学品中文名', field: 'cnName'},
          {name: '化学品英文名', field: 'enName'},
        ],

        weixianxinggaishu: [
          {name: '紧急情况概述', field: 'emergencyOverview'},
          {name: 'GHS危险性类别', field: 'ghs'},
          {name: '警示词', field: 'warningWord'},
          {name: '危险性说明', field: 'riskStatement'},
          {name: '防范说明', field: 'precautionaryStatement'},
          {name: '物理和化学危险', field: 'physicalAndChemicalHazards'},
          {name: '健康危害', field: 'healthHazard'},
          {name: '环境危害', field: 'environmentalHazard'},
        ],

        chengfenzucheng: [
          {name: '组分', field: 'component'},
          {name: '浓度或浓度范围（质量分数，%）', field: 'concentrationOrConcentrationRange'},
        ],
        jijiucuoshi: [
          {name: '急救', field: 'firstAid'},
          {name: '对于保护施救者的忠告', field: 'adviceForTheProtectionOfTheRescuer'},
        ],
        xiaofangcuoshi: [
          {name: '灭火器', field: 'extinguishingAgent'},
          {name: '特别危险性', field: 'specialDanger'},
          {name: '灭火注意事项及防护措施', field: 'protectiveMeasures'},
        ],
        xielouyingjichuli: [
          {name: '作业人员防护措施、防护装备和应急处置程序', field: 'workerProtectionMeasures'},
          {name: '环境保护措施', field: 'environmentalProtectionMeasures'},
          {name: '泄露化学品的收容、清楚方法及所使用的处置材料', field: 'containmentOfLeakingChemicals'},
        ],
        caozuochuzhiyuchucun: [
          {name: '操作注意事项', field: 'operationalPrecautions'},
          {name: '储存注意事项', field: 'storagePrecautions'},
        ],
        jiechukongzhigetifanghu: [
          {name: '生物限制', field: 'biologicalRestriction'},
          {name: '监测方法', field: 'monitoringMethod'},
          {name: '工程控制', field: 'engineeringControl'},
          {name: '个体防护装备', field: 'personalProtectiveEquipment'},
          {name: '标准来源', field: 'standardSource'},
        ],
        lihuatexing: [
          {name: '外观与性状', field: 'appearanceAndTraits9'},
          {name: '气味', field: 'odor9'},
          {name: 'ph值', field: 'ph9'},
          {name: '熔点/凝固点 (°C)', field: 'meltingPointFreezingPoint9'},
          {name: '沸点、初沸点和沸程 (°C)', field: 'boilingPointInitialBoilingPointAndBoilingRange9'},
          {name: '自燃温度 (°C)', field: 'autoIgnitionTemperature9'},
          {name: '爆炸极限 [%（体积分数）]', field: 'explosionLimit9'},
          {name: '蒸发速率 [乙酸（正）丁酯以1计]', field: 'evaporationRate9'},
          {name: '饱合蒸气压 (kPa)', field: 'saturatedVaporPressure9'},
          {name: '易燃性（固体、气体）', field: 'flammability9'},
          {name: '相对密度 (水以1计)', field: 'relativeDensity9'},
          {name: '蒸气密度（空气以1计）', field: 'vaporDensity9'},
          {name: '气味阈值 (mg/m3)', field: 'odourThreshold9'},
          {name: 'n-辛醇/水分配系数 (IgP)', field: ''},
          {name: '溶解性', field: 'solubility9'},
          {name: '黏度', field: 'viscosity9'},
        ],
        yunshuxinxi: [
          {name: '联合国危险货物编号(UN号)', field: 'unCode'},
          {name: '联合国运输名称', field: 'unName'},
          {name: '联合国危险性分类', field: 'unClassification'},
          {name: '包装类别', field: 'packingCategory'},
          {name: '包装方法', field: 'wrappingMethod'},
          {name: '海洋污染物(是/否）', field: 'marinePollutant'},
          {name: '运输注意事项', field: 'transportationConsiderations'},
        ],
      },
      record: {},
      details: {},
    }
  },

  methods: {
    isEmpty,
    /**
     * 显示弹窗
     */
    open(record) {
      this.record = record
      this.getData()
      this.visible = true
    },

    async getData() {
      this.loading = true
      const {data: {details}} = await Api.getInfo({cas: this.record['cas']})
      this.details = details
      this.loading = false
    },

    showInstructions() {
      this.$refs.InstructionsListModel.open(this.details)
    },

    /**
     * 关闭弹窗
     */
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      Api.deleted({cwId: item['cw_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },


    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
